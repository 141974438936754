@import url('https://fonts.googleapis.com/css2?family=Newsreader:ital,opsz,wght@0,6..72,400;0,6..72,600;1,6..72,400;1,6..72,600&family=Xanh+Mono:ital@0;1&display=swap');

:root {
    --color-primary: #000000;
    --color-secondary: #c4dbe0;
}

html {
    background: var(--color-primary);
}

body {
    margin: 0;
    font-family: 'Newsreader', serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 62.5%;
    background: var(--color-primary);
    color: var(--color-secondary);
    min-height: 100vh;
}

header {
    margin: 5vw;
}

h1 {
    font-size: 6rem;
    text-transform: uppercase;
    line-height: 1.20em;
    letter-spacing: 0.02em;
}

code {
    font-family: 'Xanh Mono', monospace;
    /*font-size: .8em;*/
}
